.optionsDropdown .dropdown-menu {
    right : 0;
    left : auto;
    font-weight: bold !important;
    color: white !important;
}

.dropdown-item{
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link{
    font-weight: bold;
    color: white;
}

.topHeader{
    font-family: 'Nunito', sans-serif;
}

.logoText{
    font-size: 1.5rem
}
@media screen and (max-width : 690px){
    .logoText{
        font-size: 0.9rem
    }
    .navbar-toggler {
        padding:0.25rem 0.50rem;
    }
    .navbar-toggler-icon{
        width:0.8em;
        height:0.8em;
    }
}

@media screen and (max-width : 480px){
    .logoText{
        font-size: 0.8rem;
        text-align: left!important;
    }
    .topHeader{
        padding-left: 0!important;
        padding-right:5px!important;
    }
}

@media screen and (max-width : 400px){

    .logoText{
        text-align: left!important;
    }
    .navbar-brand{
        margin-right:0!important;
    }


}

@media screen and (max-width : 280px){
    .logoText{
        font-size:0.49rem;
    }
}
