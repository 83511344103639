@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap);
html, body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.topHeader {
  background-color: #ffbf80;
  padding : 20px;
  color : #595959;
}

.contentContainer{
  min-height: 100vh;
  height: 100%;
}

.clickable:hover{
  cursor : pointer;
}

.clickemphasis:hover {
  filter : brightness(0.8)
}

.fs-12 {
  font-size: calc(0.7rem + 0.1vw) !important;
}

.fs-14 {
  font-size: calc(0.8rem + 0.1vw) !important;
}

.fs-16 {
  font-size: calc(0.9rem + 0.1vw) !important;
}

.fs-18 {
  font-size: calc(1.1rem + 0.1vw) !important;
}

.fs-20 {
  font-size: calc(1.2rem + 0.1vw) !important;
}

.fs-22 {
  font-size: calc(1.3rem + 0.1vw) !important;
}

.fs-24 {
  font-size: calc(1.4rem + 0.1vw) !important;
}

.fs-26 {
  font-size: calc(1.5rem + 0.1vw) !important;
}

.fs-30 {
  font-size: calc(1.8rem + 0.1vw) !important;
}
.greenButton {
  background-color: #27bdbe;
  border: none;
  /* width: 112px; */
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: white;
  min-width: 112px;
}
.greenButton:hover{
  background-color: #27bdbe;
}
.greyButton {
  background-color: #7b7b7b;
  border: none;
  /* width: 112px; */
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: #ffffff;
  min-width: 112px;
}
.greyButton:hover{
  background-color: #7b7b7b;
}
.redButton {
  background-color: #f42929;
  border: none;
  /* width: 112px; */
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: #ffffff;
  min-width: 112px;
}
.redButton:hover{
  background-color: #d33737;
}
.blackButton {
  background-color: #000000;
  border: none;
  /* width: 112px; */
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: white;
  min-width: 112px;
}
.blackButton:hover{
  background-color: #000000;
}

@media screen and (max-width : 767px){
  .contentContainer{
    min-height: 0px;
  }
}
html, body{
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
};

.sectionRow{
    flex-direction: row;
    justify-content: center;
};

.homeBackground {
    min-height: 100vh;
    height: 100%;
}
.imgButtonLeft{
    text-align: right;
}

.imgButtonRight{
    text-align:left;
}

@media screen and (max-width: 600px) {
    .sectionRow{
        flex-direction: column;
        justify-content: center;
        margin-top: 0 !important;
    }
    .imgButtonLeft{
        margin-top: 20px;
        text-align: center;
    }
    
    .imgButtonRight{
        margin-top: 20px;
        text-align:center;
    }

}
.modalContainer{
    padding:8% 8% 8% 8%;
}

.myBtn:hover{
    background-color : #27BDBE;
    box-shadow: 2px 2px #804000;
}
.myBtn:focus{
    background-color : #27BDBE;
    box-shadow: 2px 2px #804000;
}
.myBtn {
    background-color : #27BDBE;
    color : white;
    border-width : 0px;
}
.form-group .datePickerNoMarginTop {
    margin-top: 0;
}
input[type=checkbox]:checked{
    content: " ";
    background-color : #27BDBE !important;
    color : #27BDBE !important;
    display: inline-block;
    visibility: visible;
    /* filter: invert(64%) sepia(22%) saturate(1353%) hue-rotate(132deg) brightness(96%) contrast(84%); */
}
.MuiCheckbox-colorSecondary.Mui-checked{
    color: #27BDBE !important;
  }
.creditBorderTop {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.creditBorder {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: none;
}
.txtBoldUppercase {
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}
.txtBold {
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 1;
}
.txtGrey {
  font-family: Roboto;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #4a4a4a;
  opacity: 1;
}
.student-detail-col {
  flex-grow: 2;
}
.flex-grow-2 {
  flex-grow: 2;
}
.MuiButton-textPrimary {
  color: #27bdbe;
}
.creditBox {
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}
.creditBoxContent {
  text-align: left;
  padding: 0 30px;
}
.labelRow {
  text-align: center;
}
.arrow {
  width: 35px;
  height: 35px;
}
.tableContainer {
  min-height: 450px;
}

/* for mobile */
@media screen and (max-width : 767px){
  .tableContainer {
    min-height: 430px;
  }
  .creditBox{
    border:0px;
    border-bottom: 1px solid #C4C4C4;
  }
  .creditBoxContent{
    padding: 0
  }
  .onlyBottomBorder {
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .tableRow {
    text-align: left;
    padding-left: 1;
    padding-right: 1;
  }
  .timeRow {
    text-align: left;
    padding-left: 1; /* to try leave a left padding if enough space, so it's less cramped */
    padding-right: 0;
  }
  .creditRow {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .studentRow {
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width : 374px){
  /* remove the left padding, as there is not enough space*/
  .timeRow {
    padding-left: 0;
  }
}
#gender-select-label {
    transform : translate(.5rem,.65rem) scale(1);
    background-color: white;
}
#gender-select-label.MuiInputLabel-shrink {
    transform : translate(.5rem, -6px) scale(1);
}

div[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
.dropdownpicker {
  width: 116px;
  border: none;
  padding: 5px 10px;
  font-weight: 500;
  opacity: 0.7;
  color: #4a4a4a;
}
.filter-height {
  height: 40px;
}
.whiteBoxGallery {
  margin: 0 auto;
  max-width: 800px;
  min-width: 800px;
}
.bottomArrow {
  display: none;
}
.footerBoxGallery {
  padding: 10px 0;
  width: 70vh;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .whiteBoxGallery {
    width: 100%;
    min-width: auto;
    padding: 0 35px;
  }
}

/* for mobile */
@media screen and (max-width: 767px) {
  .filter {
    padding: 0 !important;
  }
  .dropdownpicker {
    width: 100%;
    margin-top: 5px;
  }
  .buttonGallery {
    width: 100%;
  }
  /* .galleryImg {
    width: 40vh !important;
  } */
  .topArrow {
    display: none;
  }
  .bottomArrow {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
  }
  .footerBoxGallery {
    width: 40vh;
    padding: 10px 0px;
  }
  .arrowcol{
    display: none !important;
  }
  .filterButton {
    text-align: left !important;
    padding: 0;
    /* adding these vertical margins for mobile view */
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 320px) {
  .wrappertitle {
    display: flex;
    flex-direction: column;
  }
  .wrappertitle .col{
      text-align: left !important;
      font-size: calc(0.7rem + 0.1vw) !important;
  }
}

@media screen and (max-width: 310px){
  /* .galleryImg{
    width: 100% !important;
  } */
  .fullimg{
    padding: 0 !important;
  }
  .footerBoxGallery{
    width: 90vw;
  }
}

.optionsDropdown .dropdown-menu {
    right : 0;
    left : auto;
    font-weight: bold !important;
    color: white !important;
}

.dropdown-item{
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link{
    font-weight: bold;
    color: white;
}

.topHeader{
    font-family: 'Nunito', sans-serif;
}

.logoText{
    font-size: 1.5rem
}
@media screen and (max-width : 690px){
    .logoText{
        font-size: 0.9rem
    }
    .navbar-toggler {
        padding:0.25rem 0.50rem;
    }
    .navbar-toggler-icon{
        width:0.8em;
        height:0.8em;
    }
}

@media screen and (max-width : 480px){
    .logoText{
        font-size: 0.8rem;
        text-align: left!important;
    }
    .topHeader{
        padding-left: 0!important;
        padding-right:5px!important;
    }
}

@media screen and (max-width : 400px){

    .logoText{
        text-align: left!important;
    }
    .navbar-brand{
        margin-right:0!important;
    }


}

@media screen and (max-width : 280px){
    .logoText{
        font-size:0.49rem;
    }
}

.footer{
    padding: 2% 1% 2% 1%;
    bottom:0;
    position: -webkit-sticky;
    position: sticky;
    background: #343434;
    color: white;
    width:100%;
}

.logo{
    width:200px;
}



@media screen and (max-width : 650px){
    .footerRow{
        flex-direction: column-reverse;
    }
    .footerRightColumn{
        margin-top: 10px;
        text-align: left!important;
    }

    .footerLeftColumn{
        margin-top: 10px;
        margin-bottom:5px;
        text-align: left!important;
    }
}

@media screen and (max-width : 230px){
    .logo{
        width:auto;
        max-width: 100%;
    }
}


.loginBackground {
  min-height: 100vh;
  height: 100%;
}
.loginForm {
  width: 400px;
  height: 40px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  text-align: center;
  font-family: Roboto;
  letter-spacing: 0.17px;
  color: #4a4a4a;
  background: #ffffff;
  margin: auto;
}
.forgetpassword {
  text-align: center;
  text-decoration: underline;
  font-family: Roboto;
  letter-spacing: 0.15px;
  color: #24bdbe !important;
  opacity: 1;
}
.loginButton {
  width: 400px;
  background-color: #27bdbe;
  border: none;
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: white;
}
.loginButton:hover {
  background-color: #27bdbe;
}

@media screen and (max-width : 767px){
  .loginBackground{
    min-height: 0px;
  }
}
@media screen and (max-width : 600px){
  .loginBackground{
    padding-left: 20px;
    padding-right: 20px;
  }

}

@media screen and (max-width : 500px){
  .loginBackground{
    padding-left: 10px;
    padding-right: 10px;
    
  }

  .loginButton{
    width:100%;
    border-radius: 0;

  }

  .loginForm{
    width:100%;
    border-radius: 0;
    margin-left: 5px;
    margin-right:5px;
  }

}

@media screen and (max-width : 400px){


  .loginButton{
    width:100%;
    border-radius: 0;

  }

  .loginForm{
    width:100%;
    border-radius: 0;
    margin-left: 5px;
    margin-right:5px;
  }

}


@media screen and (max-width : 280px){

  .txtBoldUppercase{
    font-size: 30px!important;
  }



}


