html, body{
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
};

.sectionRow{
    flex-direction: row;
    justify-content: center;
};

.homeBackground {
    min-height: 100vh;
    height: 100%;
}
.imgButtonLeft{
    text-align: right;
}

.imgButtonRight{
    text-align:left;
}

@media screen and (max-width: 600px) {
    .sectionRow{
        flex-direction: column;
        justify-content: center;
        margin-top: 0 !important;
    }
    .imgButtonLeft{
        margin-top: 20px;
        text-align: center;
    }
    
    .imgButtonRight{
        margin-top: 20px;
        text-align:center;
    }

}