input[type=checkbox]:checked{
    content: " ";
    background-color : #27BDBE !important;
    color : #27BDBE !important;
    display: inline-block;
    visibility: visible;
    /* filter: invert(64%) sepia(22%) saturate(1353%) hue-rotate(132deg) brightness(96%) contrast(84%); */
}
.MuiCheckbox-colorSecondary.Mui-checked{
    color: #27BDBE !important;
  }