.myBtn:hover{
    background-color : #27BDBE;
    box-shadow: 2px 2px #804000;
}
.myBtn:focus{
    background-color : #27BDBE;
    box-shadow: 2px 2px #804000;
}
.myBtn {
    background-color : #27BDBE;
    color : white;
    border-width : 0px;
}