div[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
.dropdownpicker {
  width: 116px;
  border: none;
  padding: 5px 10px;
  font-weight: 500;
  opacity: 0.7;
  color: #4a4a4a;
}
.filter-height {
  height: 40px;
}
.whiteBoxGallery {
  margin: 0 auto;
  max-width: 800px;
  min-width: 800px;
}
.bottomArrow {
  display: none;
}
.footerBoxGallery {
  padding: 10px 0;
  width: 70vh;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .whiteBoxGallery {
    width: 100%;
    min-width: auto;
    padding: 0 35px;
  }
}

/* for mobile */
@media screen and (max-width: 767px) {
  .filter {
    padding: 0 !important;
  }
  .dropdownpicker {
    width: 100%;
    margin-top: 5px;
  }
  .buttonGallery {
    width: 100%;
  }
  /* .galleryImg {
    width: 40vh !important;
  } */
  .topArrow {
    display: none;
  }
  .bottomArrow {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
  }
  .footerBoxGallery {
    width: 40vh;
    padding: 10px 0px;
  }
  .arrowcol{
    display: none !important;
  }
  .filterButton {
    text-align: left !important;
    padding: 0;
    /* adding these vertical margins for mobile view */
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 320px) {
  .wrappertitle {
    display: flex;
    flex-direction: column;
  }
  .wrappertitle .col{
      text-align: left !important;
      font-size: calc(0.7rem + 0.1vw) !important;
  }
}

@media screen and (max-width: 310px){
  /* .galleryImg{
    width: 100% !important;
  } */
  .fullimg{
    padding: 0 !important;
  }
  .footerBoxGallery{
    width: 90vw;
  }
}
