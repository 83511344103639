.footer{
    padding: 2% 1% 2% 1%;
    bottom:0;
    position: sticky;
    background: #343434;
    color: white;
    width:100%;
}

.logo{
    width:200px;
}



@media screen and (max-width : 650px){
    .footerRow{
        flex-direction: column-reverse;
    }
    .footerRightColumn{
        margin-top: 10px;
        text-align: left!important;
    }

    .footerLeftColumn{
        margin-top: 10px;
        margin-bottom:5px;
        text-align: left!important;
    }
}

@media screen and (max-width : 230px){
    .logo{
        width:auto;
        max-width: 100%;
    }
}

