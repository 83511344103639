.loginBackground {
  min-height: 100vh;
  height: 100%;
}
.loginForm {
  width: 400px;
  height: 40px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  text-align: center;
  font-family: Roboto;
  letter-spacing: 0.17px;
  color: #4a4a4a;
  background: #ffffff;
  margin: auto;
}
.forgetpassword {
  text-align: center;
  text-decoration: underline;
  font-family: Roboto;
  letter-spacing: 0.15px;
  color: #24bdbe !important;
  opacity: 1;
}
.loginButton {
  width: 400px;
  background-color: #27bdbe;
  border: none;
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: white;
}
.loginButton:hover {
  background-color: #27bdbe;
}

@media screen and (max-width : 767px){
  .loginBackground{
    min-height: 0px;
  }
}
@media screen and (max-width : 600px){
  .loginBackground{
    padding-left: 20px;
    padding-right: 20px;
  }

}

@media screen and (max-width : 500px){
  .loginBackground{
    padding-left: 10px;
    padding-right: 10px;
    
  }

  .loginButton{
    width:100%;
    border-radius: 0;

  }

  .loginForm{
    width:100%;
    border-radius: 0;
    margin-left: 5px;
    margin-right:5px;
  }

}

@media screen and (max-width : 400px){


  .loginButton{
    width:100%;
    border-radius: 0;

  }

  .loginForm{
    width:100%;
    border-radius: 0;
    margin-left: 5px;
    margin-right:5px;
  }

}


@media screen and (max-width : 280px){

  .txtBoldUppercase{
    font-size: 30px!important;
  }



}
