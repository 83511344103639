.creditBorderTop {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.creditBorder {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: none;
}
.txtBoldUppercase {
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}
.txtBold {
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 1;
}
.txtGrey {
  font-family: Roboto;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #4a4a4a;
  opacity: 1;
}
.student-detail-col {
  flex-grow: 2;
}
.flex-grow-2 {
  flex-grow: 2;
}
.MuiButton-textPrimary {
  color: #27bdbe;
}
.creditBox {
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}
.creditBoxContent {
  text-align: left;
  padding: 0 30px;
}
.labelRow {
  text-align: center;
}
.arrow {
  width: 35px;
  height: 35px;
}
.tableContainer {
  min-height: 450px;
}

/* for mobile */
@media screen and (max-width : 767px){
  .tableContainer {
    min-height: 430px;
  }
  .creditBox{
    border:0px;
    border-bottom: 1px solid #C4C4C4;
  }
  .creditBoxContent{
    padding: 0
  }
  .onlyBottomBorder {
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .tableRow {
    text-align: left;
    padding-left: 1;
    padding-right: 1;
  }
  .timeRow {
    text-align: left;
    padding-left: 1; /* to try leave a left padding if enough space, so it's less cramped */
    padding-right: 0;
  }
  .creditRow {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .studentRow {
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width : 374px){
  /* remove the left padding, as there is not enough space*/
  .timeRow {
    padding-left: 0;
  }
}